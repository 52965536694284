import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout.js";
import HeroSection from "../components/HeroSection";
import RenderMarkdown from "../components/utilities/RenderMarkdown.js";
import PreviewCompatibleImage from "../components/utilities/PreviewCompatibleImage.js";
import LinkSetList from "../components/utilities/LinkSetList.js";
import Section from "../components/Section.js";
import Steps from "../components/Steps.js";

const ZoomLinkPageTemplate = ({ data }) => {
  return (
    <Layout
      imageObject={data.headerObject.imageObject}
      seoObject={data.seoObject}
    >
      <HeroSection>
        <div className="space-y-5 sm:space-y-10">
          <RenderMarkdown
            markdownContent={data.heroObject.title}
            className="text-center"
          />
          <div className="max-h-[30vh] max-w-3xl mx-auto overflow-hidden">
            <PreviewCompatibleImage
              imageObject={data.heroObject.imageObject}
              className="h-full overflow-hidden"
            />
          </div>
          <RenderMarkdown markdownContent={data.heroObject.description} />
        </div>
        <LinkSetList
          linkSetList={data.heroObject.linkSetList}
          className="max-w-2xl mx-auto"
        />
      </HeroSection>
      {data.stepsObject && data.stepsObject.steps.length > 0 && (
        <Section className="bg-brand-beige-40">
          <Steps stepsObject={data.stepsObject} />
        </Section>
      )}
    </Layout>
  );
};

const ZoomLinkPage = ({ data }) => {
  return <ZoomLinkPageTemplate data={data.markdownRemark.frontmatter} />;
};

export default ZoomLinkPage;

export const pageQuery = graphql`
  query ImageMessage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seoObject {
          slug
          domain
          description
          index
          title
          imageObject {
            alt
            image {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
        headerObject {
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
        }
        heroObject {
          title
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          description
          linkSetList {
            linkText
            linkSet {
              linkUrl
            }
            linkSubtitle
          }
        }
        stepsObject {
          title
          steps {
            imageObject {
              alt
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
              linkUrl
            }
            video {
              format
              cloudflareId
              hasGifThumbnail
            }
            description
            anchor
            linkSetList {
              linkText
              linkSet {
                linkUrl
              }
              linkSubtitle
            }
          }
        }
      }
    }
  }
`;
